import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import Link from '../Link';
import styles from './styles';
import { isBrowser } from '../../helpers';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => styles(theme));

export default function Breadcrumb(props) {
    const classes = useStyles();

    const [isMobile, setMobile] = useState(true);

    useEffect(() => {
        if (isBrowser) {
            if (window.innerWidth > 960) {
                setMobile(false);
            }
        }
    }, [isMobile]);

    const data = useStaticQuery(graphql`
    query breadcrumbItems {
      wp {
        themeOptions {
          blogPath
          blogPathTitle
        }
      }
    }
  `);

  function filterMainCategorySlug(name) {
    if (name.includes('LV')) {
      return name.replace('LV', '').trim();
    }

    if (name.includes('CV')) {
      return name.replace('CV', '').trim();
    }

    return name;
  }

    return (
        <div className={`breadcrumb ${classes.breadcrumb} ${classes.breadcrumbTheme}`}>
            <Breadcrumbs 
                maxItems={isMobile ? 2 : 8}
                itemsAfterCollapse={2}
                itemsBeforeCollapse={0}
                separator=""
                aria-label="breadcrumbs"
            >
                <Link to="/"><span>Home</span></Link>

                {props.type === "page" && props.ancestors &&
                    <>
                        {props.ancestors.nodes.map(link => {
                            return <Link to={link.uri} key={link.id}><span>{link.title}</span></Link>
                        })}
                    </>
                }

                {props.type === 'post' &&
                    <Link to={'/' + data.wp.themeOptions.blogPath}><span>{data.wp.themeOptions.blogPathTitle}</span></Link>
                }

                {props.type === "product" && props.ancestors !== null && props.ancestors.map(link => {
                    return <Link to={link.uri} key={link.id}>{filterMainCategorySlug(link.name)}</Link>
                })}

                {props.type === "course" && <Link to="/training/">Training</Link>}
                {props.type === "course" && props.courseCategories && props.courseCategories.length > 0 && <Link to="/training/">{props.courseCategories[0].name}</Link>}

                {props.type === "category" && props.parent &&
                <Link to={props.parent.uri}>{filterMainCategorySlug(props.parent.name)}</Link>}

                <Typography>{filterMainCategorySlug(props.current.title)}</Typography>
            </Breadcrumbs>
        </div>
    )
}