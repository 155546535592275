export default theme => ({
    breadcrumb: {
        position: 'relative',
        padding: '56px 0 20px',
        zIndex: 2,
        [theme.breakpoints.up('md')]: {
            padding: '28px 0 20px',
        },
        '&::before': {
            content: '""',
            backgroundColor: '#F6F7F8',
            width: '100vw',
            top: 0,
            bottom: 0,
            left: '50%',
            zIndex: -1,
            position: 'absolute',
            transform: 'translateX(-50%)',
        },
        '& nav': {
            '& .MuiBreadcrumbs-separator': {
                marginLeft: 0,
            },
            '& li': {
                fontSize: 0,
                whiteSpace: 'none',
                '& a, & p': {
                    fontSize: 12,
                    lineHeight: 1.6,
                    letterSpacing: '0.016em',
                    fontFamily: 'Overpass',
                    color: theme.palette.primary.main,
                },
                '& p': {
                    opacity: .5,
                },
                '& a': {
                    color: theme.palette.primary.main,
                    textDecoration: 'none',
                    '& span': {
                        textDecoration: 'underline',
                    },
                    '&::after': {
                        content: '"/"',
                        marginLeft: 8,
                        opacity: .5,
                    }
                },
                '&.MuiButtonBase-root': {
                    backgroundColor: 'transparent',
                    '&::before': {
                        content: '"<<"',
                        fontSize: 10,
                        lineHeight: 1.6,
                        textTransform: 'uppercase',
                        letterSpacing: '0.152em',
                        textDecoration: 'none',
                        color: theme.palette.primary.main,
                        fontWeight: 500,
                    },
                    '& svg': {
                        display: 'none',
                    }
                }
            }
        }
    },
    breadcrumbTheme: {
        ...theme.breadcrumbTheme,
    }
});