import React, { useEffect } from "react";

import Layout from "../components/layout";
import Breadcrumb from "../components/Breadcrumb";

export default function Privacy({ data }) {

  useEffect(() => {
		const script = document.createElement('script');

		script.src = "https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js";
		script.async = true;
		script.id = "otprivacy-notice-script";
		script.text = 'settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9"';
		script.onload = () => {
			window.OneTrust.NoticeApi.Initialized.then(function() {
				window.OneTrust.NoticeApi.LoadNotices(["https://privacyportal-cdn.onetrust.com/9e3840d9-d3d2-4665-ac3b-b1cdce104745/privacy-notices/68543441-4981-4d60-b04f-eba65d61969e.json"])
			});
		}
		document.body.appendChild(script);

		return () => {
		  document.body.removeChild(script);
		}
	  }, []);

  return (
    <Layout
      meta={{
        ...data.wpPage.Meta,
        canonical: "/privacy-notice/",
      }}
      path={"/privacy-notice/"}
      cta={"a"}
      themeOptions={data.wp.themeOptions}
    >
      <Breadcrumb
        type="page"
        current={{
          title: "Privacy Notice",
          slug: "privacy-notice",
          uri: "/privacy-notice",
        }}
      />
      {/* <!-- OneTrust Privacy Notice start --><!-- Container in which the privacy notice will be rendered --> */}
      <div class="otnotice" id="otnotice-68543441-4981-4d60-b04f-eba65d61969e">&nbsp;</div>
      {/* <!--OneTrust Privacy Notice end-- > */}
    </Layout>
  );
}

export const query = graphql`
  query {
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;
